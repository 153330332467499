<template>
  <div class="pda-report">
    <div class="head">
      <div class="rtit" style="padding-right: 20px; font-size: 0.2rem">
        {{
          `${$store.state.schoolInfo.atteSchool.schoolName}-${$route.query.classReport}-${$route.query.class}【${$route.query.grade}】`
        }}-系统平台班级成长测评报告
        <label for="" style="float: right"
          >最近测试时间:
          {{ tzList.length ? tzList[0].createtime : "--" }}</label
        >
      </div>
      <div class="cont">
        学员测评报告主要来自于全国足球特色幼儿园系统平台的专业测评。我们针对幼儿园阶段不同年级的孩子提供标准化的测试项目。测评项目分体质测试和运动测试两大模块。体质测试分身高、体重、视力、臂展、肺活量、胸围、体脂率、坐姿体前屈；运动测试分跑动、跳跃、敏捷、平衡、协调、力量、物体控制-手、物体控制-脚。
      </div>
    </div>
    <div>
      <div class="rtit2"><span>评级概况</span></div>
      <div class="cont-2">
        反映孩子参与PDA测试项目评级（优秀、良好与需努力）的比例，并从两次对比中可以观察到测试评级比例的变化情况。
      </div>
      <div class="chartdiv">
        <div class="basic-test" style="width: 100%">
          <div class="flex" style="align-items: flex-start">
            <div style="background: #f3f3f3; flex: 1" class="chartbox">
              <div class="name">体质测试</div>
              <div class="testbox" style="padding-top: 0px">
                <img src="../../../assets/img/pda/tsc.png" class="center" />
                <div class="row">
                  <img src="../../../assets/img/pda/ts1.png" class="bg" />
                  <div class="item left">
                    <b>{{
                      tzList[0].testingFoundation.title +
                      "：" +
                      (tzList[0].avgData ? tzList[0].avgData : "0") +
                      handleDw(tzList[0].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[0].country_data ||
                        "0" + handleDw(tzList[0].testingFoundation.title)
                      }}</span
                    >
                  </div>
                  <div class="item right">
                    <b>{{
                      tzList[1].testingFoundation.title +
                      "：" +
                      (tzList[1].avgData ? tzList[1].avgData : "0") +
                      handleDw(tzList[1].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[1].country_data ||
                        "0" + handleDw(tzList[1].testingFoundation.title)
                      }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <img src="../../../assets/img/pda/ts2.png" class="bg" />
                  <div class="item left">
                    <b>{{
                      tzList[2].testingFoundation.title +
                      "：" +
                      (tzList[2].avgData ? tzList[2].avgData : "0") +
                      handleDw(tzList[2].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[2].country_data ||
                        "0" + handleDw(tzList[2].testingFoundation.title)
                      }}</span
                    >
                  </div>
                  <div class="item right">
                    <b>{{
                      tzList[3].testingFoundation.title +
                      "：" +
                      (tzList[3].avgData ? tzList[3].avgData : "0") +
                      handleDw(tzList[3].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[3].country_data ||
                        "0" + handleDw(tzList[3].testingFoundation.title)
                      }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <img src="../../../assets/img/pda/ts3.png" class="bg" />
                  <div class="item left">
                    <b>{{
                      tzList[4].testingFoundation.title +
                      "：" +
                      (tzList[4].avgData ? tzList[4].avgData : "0") +
                      handleDw(tzList[4].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[4].country_data ||
                        "0" + handleDw(tzList[4].testingFoundation.title)
                      }}</span
                    >
                  </div>
                  <div class="item right">
                    <b>{{
                      tzList[5].testingFoundation.title +
                      "：" +
                      (tzList[5].avgData ? tzList[5].avgData : "0") +
                      handleDw(tzList[5].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[5].country_data ||
                        "0" + handleDw(tzList[5].testingFoundation.title)
                      }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <img src="../../../assets/img/pda/ts4.png" class="bg" />
                  <div class="item left">
                    <b>{{
                      tzList[6].testingFoundation.title +
                      "：" +
                      (tzList[6].avgData ? tzList[6].avgData : "0") +
                      handleDw(tzList[6].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[6].country_data ||
                        "0" + handleDw(tzList[6].testingFoundation.title)
                      }}</span
                    >
                  </div>
                  <div class="item right">
                    <b>{{
                      tzList[7].testingFoundation.title +
                      "：" +
                      (tzList[7].avgData ? tzList[7].avgData : "0") +
                      handleDw(tzList[7].testingFoundation.title)
                    }}</b>
                    <span
                      >全国平均值：{{
                        tzList[7].country_data ||
                        "0" + handleDw(tzList[7].testingFoundation.title)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-left: 20px; height: 5rem" class="chartbox">
              <div class="name">运动测试</div>
              <div class="flex">
                <div id="chart-3" class="chart"></div>
                <div class="arr"></div>
                <div id="chart-4" class="chart"></div>
              </div>
              <div class="colors">
                <div class="c1"><i></i>需努力</div>
                <div class="c2"><i></i>合格</div>
                <div class="c3"><i></i>良好</div>
                <div class="c4"><i></i>优秀</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="rtit">测试记录</div>
    </div>
    <div class="head">
      <div class="rtit2"><span>体质测试</span></div>
      <table class="layui-table">
        <thead>
          <tr>
            <th>时间</th>
            <th v-for="(item, index) in tzList" :key="index">
              {{ item.testingFoundation.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ tzList[0].createtime ? tzList[0].createtime : "--" }}</td>
            <td v-for="(item, index) in tzList" :key="index">
              {{ item.avgData || "" }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <!-- <tr>
            <td>{{tzList.length ? tzList[0].createtime : '--'}}</td>
            <td><span class="up">+{$row['promote']}</span></td>
            <td><span class="down">-{$row['promote']}</span></td>
          </tr> -->
        </tfoot>
      </table>
    </div>
    <div class="head">
      <div class="rtit2"><span>运动测试</span></div>
      <table class="layui-table">
        <thead>
          <tr>
            <th>时间</th>
            <th v-for="(item, index) in ydList" :key="index">
              {{ item.testingSports.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ydList[0].createtime ? common.dateFormat(ydList[0].createtime * 1000, "yyyy-MM-dd") : '--' }}
            </td>
            <td v-for="(item, index) in ydList" :key="index">
              {{ item.avgData || "--" }}
            </td>
          </tr>
          <!-- <tr>
            {notempty name="sportslist"} {notempty
            name="sportslist[0]['history_createtime']"}
            <td>{$sportslist[0]['history_createtime']|date="Y-m-d",###}</td>
            {else /}
            <td></td>
            {/notempty} {volist name="sportslist" id="row"} {notempty
            name="row['history_log']"}
            <td>{$row['history_log']}</td>
            {else /}
            <td></td>
            {/notempty} {/volist} {/notempty}
          </tr> -->
        </tbody>
        <!-- <tfoot>
          <tr>
            <td></td>
            {notempty name="sportslist"} {volist name="sportslist" id="row"} {if
            isset($row['promote']) && $row['promote']!==''} {if
            count(explode("/",$row['promote']))>1 }
            <td><span>{$row['promote']}</span></td>
            {else /} {if ($row['promote']*1)>0 }
            <td><span class="up">+{$row['promote']*1}</span></td>
            {else /}
            <td><span class="down">-{$row['promote']*1}</span></td>
            {/if} {/if} {else /}
            <td></td>
            {/if} {/volist} {/notempty}
          </tr>
        </tfoot> -->
      </table>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "pda_report_page1",
  components: {},
  data() {
    return {
      tzList: [
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
      ],
      ydList: [{ createtime: "", testingSports: { title: "" } }],
    };
  },
  created() {},
  mounted() {
    this.getTZ();
    this.getYD();
    var id_3 = "chart-3";
    var tit_3 = "2020-2-1\n体测结果综合评级";
    var colors_3 = ["#db606f", "#e1be46", "#28a082", "#284691"];
    var data_3 = [
      { value: 0, name: "需努力" },
      { value: 0, name: "合格" },
      { value: 0, name: "良好" },
      { value: 0, name: "优秀" },
    ];

    var id_4 = "chart-4";
    var tit_4 = "2020-7-30\n体测结果综合评级";
    var colors_4 = ["#db606f", "#e1be46", "#28a082", "#284691"];
    var data_4 = [
      { value: 0, name: "需努力" },
      { value: 13.56, name: "合格" },
      { value: 86.44, name: "良好" },
      { value: 0, name: "优秀" },
    ];
    this.$nextTick(() => {
      this.initChart(id_3, tit_3, colors_3, data_3);
      this.initChart(id_4, tit_4, colors_4, data_4);
    });
  },
  methods: {
    initChart(id, tit, colors, data) {
      var chart = echarts.init(document.getElementById(id));
      // var app = {};
      let option = null;
      option = {
        title: {
          text: tit,
          left: "center",
          top: "bottom",
          textStyle: {
            color: "#000",
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        grid: {
          left: 0,
          right: 0,
        },
        series: [
          {
            // name: '2019年9月26日体测结果综合评级',
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            label: {
              formatter: "{d}%",
            },
            labelLine: {
              show: false,
              length: 5,
              length2: 5,
            },
            color: colors,
            data: data,
          },
        ],
      };
      if (option && typeof option === "object") {
        chart.setOption(option, true);
      }
    },
    //   单位转化
    handleDw(val) {
      if (val) {
        let str = "";
        if (val.indexOf("/") != -1) {
          str = val.split("/")[1];
        }
        let res = "";
        if (str) {
          let temp = str.split("");
          temp.unshift("/");
          res = temp.join("");
        }
        return res == "视力" ? "" : res;
      }
    },
    // 体质测试
    getTZ() {
      /* let data = {
        semesterId: this.$route.query.semesterId,
        gradeId: this.$route.query.gradeId,
        isEnd: this.$route.query.isEnd,
      };
      console.log("父元素",this.$route.query);
      this.api.pes.findReportByGradeId(data).then((res) => {
         console.log("体质",res);
        if (res.flag == true) {
          console.log(123);
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        } */
      this.tzList = this.$route.query.data;
      this.tzList.forEach((item) => {
        if(item.createtime){
          item.createtime = this.common.dateFormat(
            item.createtime * 1000,
            "yyyy-MM-dd"
          );
        }
      });

      /* }); */
    },
    // 运动测试
    getYD() {
      let arr;
      if (this.$route.query.grade == "大班") {
        arr = 0;
      } else if (this.$route.query.grade == "中班") {
        arr = 1;
      } else {
        arr = 2;
      }
      let data = {
        semesterId: this.$route.query.semesterId,
        gradeId: this.$route.query.gradeId,
        isEnd: this.$route.query.isEnd,
        type: arr,
      };
      this.api.pes.findReport2ByGradeId(data).then((res) => {
        this.ydList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pda_report_page1 {
  width: 100%;
}
</style>